import logo from './The-National-Roast-logos_transparent.png';
import './App.css';
const Foot = (props ) => {

  return (
    <>
    <h2>Powered by : {props.name1} and {props.name2} </h2>
    </>
  )

}



const App = ()=>  {
  return (
    <div className="App">
      <div className="App-header">
        <h1>The National Roast</h1>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div className='Footer'>
        <Foot name1={'Louis'} name2={'Eluned'}/>
      </div>
    </div>
  );
}

export default App;
